import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { editContact } from "../redux/slices/contactReducer";

const EditContact = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("");
  const contacts = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentContact = contacts.find(
    (contact) => contact.id === parseInt(id)
  );

  useEffect(() => {
    if (currentContact) {
      setName(currentContact.name);
      setEmail(currentContact.email);
      setNumber(currentContact.number);
      setStatus(currentContact.status);
    }
  }, [currentContact]);
  const checkEmail = contacts.find(
    (contact) => contact.id !== parseInt(id) && contact.email === email
  );
  const checkNumber = contacts.find(
    (contact) =>
      contact.id !== parseInt(id) && contact.number === parseInt(number)
  );
  //Deshabilitado para futura actualizacion de diferentes status de membresia.
  // const handleChange = (e) => {
  //   setStatus({ selectedValue: e.target.value });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !number) {
      return toast.warning("Por favor rellenar todos los campos");
    }
    if (checkEmail) {
      return toast.error("Este correo ya esta registrado");
    }
    if (checkNumber) {
      return toast.error("Este numero de dni ya esta registrado");
    }
    const data = {
      id: parseInt(id),
      name,
      email,
      number,
      status,
    };
    dispatch(editContact(data));
    toast.success("Contacto actualizado satisfactoriamente");

    navigate("/");
  };

  return (
    <div className="container">
      {currentContact ? (
        <>
          <div className="row">
            <div className="col-md-6 shadow mx-auto p-5">
              <form onSubmit={handleSubmit}>
                <div className="form-group ">
                  <input
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group my-3">
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group my-3">
                  <input
                    type="number"
                    placeholder="Dni"
                    className="form-control"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="form-group my-3">
                  <div>Select</div>
                  {/* <NavDropdown title={status} id="basic-nav-dropdown">
                    <NavDropdown.Item
                      onClick={(e) => setStatus(e.target.value)}
                    >
                      <option value="Black" onChange={(e) => handleChange(e)}>
                        Black
                      </option>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={(e) => setStatus(e.target.value)}
                    >
                      <option
                        value="Platinum"
                        onChange={(e) => handleChange(e)}
                      >
                        Platinum
                      </option>
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </div>
                <div className="form-group ">
                  <input
                    type="submit"
                    placeholder="Submit"
                    value="Actualizar Contacto"
                    className="btn btn-dark mr-5"
                  />
                  <Link to="/" className="btn btn-danger m-3">
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <h1 className="display-3 my-5 text-center">Contacto {id} no existe</h1>
      )}
    </div>
  );
};

export default EditContact;
