import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Usuario from "./Usuario";
import { Link } from "react-router-dom";
import "../../styles/AdminPanel.css"
import { toast } from "react-toastify";

const AdminPanel = () => {
  const [activeSection, setActiveSection] = useState("Usuarios");
  const adminSections = ["Usuarios"];
  const isLogIn = useSelector((state) => state.isLogIn);

  useEffect(() => {
    if (!isLogIn) toast.success("Por favor Inicie Sesión");
  }, []);
  
  const user = useSelector((state) => state.loggedUser);
  const capitalizeUser = user ? user.name : null;

  const changeSection = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="container" >
      {!isLogIn ? (
        <div className="imagenAdmin">
          <img
            src="http://aajc.com.ar/home/wp-content/uploads/2020/01/logo.png"
            alt=""
            
          />
        </div>
      ) : (
      <div className="container">
        <div className="col-md-12 my-5 text-end">
            <Link to="/addAdmin" className="btn btn-outline-dark">
              Agregar Administrador
            </Link>
          </div>
        {capitalizeUser ? (
          <h2 className="mt-4">
            Hola {" "}
            {capitalizeUser.charAt(0).toUpperCase() + capitalizeUser.slice(1)}
          </h2>
        ) : (
          <></>
        )}

        <div className="admin-content mt-4 mb-4">
          <div className="row admin-navbar-container ">
            <div className="col-12 admin-navbar p-0">
              <div className="row h-100 m-0">
                {adminSections.map((e) => {
                  return (
                    <div
                      className="col-4 d-flex justify-content-center align-items-center admin-navbar-section "
                      onClick={() => changeSection(e)}
                      
                    >
                      <p className="m-0"></p>
                      {activeSection === e ? (
                        <span className="navbar-active"></span>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="col-12 admin-section-container">
            <div className="">
              {activeSection === "Usuarios" ? <Usuario /> : null}
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default AdminPanel;
