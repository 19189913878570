import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addContact, getContacts } from "../redux/slices/contactReducer";

const AddContact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contacts = useSelector((state) => state.contact);
  const checkEmail = contacts.find(
    (contact) => contact.email === email && email
  );
  const checkNumber = contacts.find(
    (contact) => contact.number === parseInt(number)
  );
  //  Deshabilitado para cambios futuros de status
  //const handleChange = (e) => {
  //   setStatus({ selectedValue: e.target.value });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !email || !number) {
      return toast.warning("Por favor rellenar todos los campos");
    }
    if (checkEmail) {
      return toast.error("Este correo ya esta registrado");
    }
    if (checkNumber) {
      return toast.error("Este numero de dni ya esta registrado");
    }
    const data = {
      name,
      email,
      number,
      status,
    };

    dispatch(addContact(data));

    toast.success("Contacto agregado satisfactoriamente");
    navigate("/");
  };

  return (
    <div className="container">
      <h1 className="display-3 my-5 text-center">Agregar Contacto</h1>

      <div className="row">
        <div className="col-md-6 shadow mx-auto p-5">
          <form onSubmit={handleSubmit}>
            <div className="form-group ">
              <input
                type="text"
                placeholder="Nombre y Apellido"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <input
                type="email"
                placeholder="Correo"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <input
                type="number"
                placeholder="Dni"
                className="form-control"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
            {/* <div className="form-group ">
            <div>Select</div> */}
              {/* <NavDropdown title="Membresia" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={(e) => setStatus(e.target.value)}>
                  <option value="Black" onChange={(e) => handleChange(e)}>
                    Black
                  </option>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => setStatus(e.target.value)}>
                  <option value="Platinum" onChange={(e) => handleChange(e)}>
                    Platinum
                  </option>
                </NavDropdown.Item>
              </NavDropdown> */}
            {/* </div> */}
            <div className="form-group my-3">
              <input
                type="submit"
                placeholder="Submit"
                value="Agregar Contacto"
                className="btn btn-block btn-dark"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddContact;
