import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addAdmin } from "../redux/slices/adminReducer";

const AddAdmin = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.admin);

  const checkEmail = users.find((users) => users.email === email && email);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !password) {
      return toast.warning("Por favor rellenar todos los campos");
    }
    if (checkEmail) {
      return toast.error("Este correo ya esta registrado");
    }
    
    const data = {
      name,
      email,
      password
    };
    
    dispatch(addAdmin(data));

    toast.success("Administrador agregado satisfactoriamente");
    navigate("/");
  };

  return (
    <div className="container">
      <h1 className="display-3 my-5 text-center">Agregar Administrador</h1>
      <div className="row">
        <div className="col-md-6 shadow mx-auto p-5">
          <form onSubmit={handleSubmit}>
            <div className="form-group ">
              <input
                type="text"
                placeholder="Nombre y Apellido"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <input
                type="email"
                placeholder="Correo"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <input
                type="password"
                placeholder="Contraseña"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <input
                type="submit"
                placeholder="Submit"
                value="Agregar Administrador"
                className="btn btn-block btn-dark"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
