import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Home.css";
import { useSelector } from "react-redux";

const Navbar = () => {
  const isLogIn = useSelector((state) => state.isLogIn);

  return (
    <Nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="nav">
        <Link to="/" className="navbar-brand">
          AAJC
        </Link>
        <Link to="/login" className="navbar-brand ml-5">
          Inicio de sesión
        </Link>
        {isLogIn ? (
          <Link to="/admin" className="navbar-brand ml-5">
            Panel de Administrador
          </Link>
        ) : null}
      </div>
    </Nav>
  );
};

export default Navbar;
