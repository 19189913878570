import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "../styles/Home.css";
import { getContacts, deleteContact } from "../redux/slices/contactReducer";

const Home = () => {
  const contacts = useSelector((state) => [...state.contact]);
  const isLogIn = useSelector((state) => state.isLogIn);
  const user = useSelector((state) => state.loggedUser);
  const dispatch = useDispatch();

  let handleError = true;

  const handleRemove = (id) => {
    handleError = !handleError;
    const dato = {
      id: id,
    };
    dispatch(deleteContact(dato)).then(() => dispatch(getContacts(), []));
  };
  const handleRefresh = () => {
    if (isLogIn) dispatch(getContacts());
  };

  useEffect(() => {
    if (isLogIn) dispatch(getContacts());
    else toast.success("Por favor Inicie Sesión");
  }, []);

  return (
    <div className="container">
      {!isLogIn ? (
        <div className="imagen">
          <img
            src="http://aajc.com.ar/home/wp-content/uploads/2020/01/logo.png"
            alt=""
          />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12 my-5 text-end" style={{display:"flex", justifyContent:"end"}}>
            <div  style={{paddingRight:"5px"}}>
            <Link to="/add" className="btn btn-outline-dark">
              Agregar Contacto
            </Link>
            </div>
            <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleRefresh()}
            >
              Refrescar
            </button>
            </div>
          </div>
          <div className="col-md-12 mx-auto">
            <table className="table table-hover">
              <thead className="text-white bg-dark text-left ">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Email</th>
                  <th scope="col">Dni</th>
                  {/* <th scope="col">Membresia</th> */}
                  <th scope="col">Accion</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, id) => (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td>{contact.name}</td>
                    <td>{contact.email}</td>
                    <td>{contact.number}</td>
                    {/* <td>{contact.status}</td> */}
                    {/* <td>Select</td> */}
                    <td>
                      <div style={{display:"flex"}}>
                        <Link
                          to={`edit/${contact.id}`}
                          className="btn btn-small btn-primary pr-2 "
                        >
                          Editar
                        </Link>
                        <div style={{ paddingLeft: "10px" }}>
                          <button
                            type="button"
                            onClick={() => handleRemove(contact.id)}
                            className="btn btn-small btn-danger"
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
