/** @format */

import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserVoid } from "../redux/slices/loggedUserReducer";
import { useNavigate } from "react-router-dom";
import { setLogginTrue, setLogginFalse } from "../redux/slices/authReducer";
import { getContacts } from "../redux/slices/contactReducer";
import { toast } from "react-toastify";

import "../styles/Login.css";

export default function Login(props) {
  const isLogIn = useSelector((state) => state.isLogIn);
  const dispatch = useDispatch();
  const [value, setValue] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const user = useSelector((state) => state.loggedUser);

  const onChange = ({ target }) => {
    setValue((value) => {
      return {
        ...value,
        [target.name]: target.value,
      };
    });
  };

  const onSubmit = (e) => {
    if (e.target) e.preventDefault();

    axios
      .post("/api/auth/login", value)

      .then((res) => res.data)
      .then((data) => {
        localStorage.setItem("aajc", `Bearer ${data.token}`);
        dispatch(setLogginTrue());
        dispatch(setUser());
        getContacts();
        navigate("/");
      })
      .catch((err) => {
        toast.error("Usuario y/o Contraseña incorrectos");
        dispatch(setLogginFalse());
      });
  };
  function logOut() {
    localStorage.removeItem("aajc");
    dispatch(setUserVoid());
  }

  return (
    <div>
      <section className="login">
        <section className="login__container">
          {!isLogIn ? (
            <h2>Inicio de sesión</h2>
          ) : (
            <h4 className="mt-4">
              Hola, has iniciado sesión como: 
              <br/>
              <br/>
              <h3>{user.name}</h3>
            </h4>
          )}

          <form className="login__container--form">
            {!isLogIn ? (
              <div>
                <input
                  onChange={onChange}
                  name="email"
                  className="input"
                  type="text"
                  placeholder="Email"
                />
                <input
                  onChange={onChange}
                  name="password"
                  className="input"
                  type="password"
                  placeholder="Contraseña"
                />
              </div>
            ) : null}
            {!isLogIn ? (
              <button className="button" onClick={onSubmit}>
                Iniciar sesión
              </button>
            ) : (
              <button className="button" onClick={logOut}>
                Cerrar sesión
              </button>
            )}
          </form>
        </section>
      </section>
    </div>
  );
}
