import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdmins, deleteUser } from "../../redux/slices/adminReducer";

import "./admin.css";

const Usuario = () => {
  const user = useSelector((state) => state.loggedUser);
  const token = localStorage.getItem("aajc");
  const allUsers = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAdmins(token));
  }, [user, dispatch, token]);

  const handleRemove = (id) => {
    const dato = {
      id: id,
    };
    dispatch(deleteUser(dato)).then(() => dispatch(getAllAdmins(), []));
  };

  return (
    <div style={{ padding: "15px" }}>
      <h4 className="admin-section-title">Administradores</h4>

      {allUsers.length ? (
        allUsers.map((user, index) => {
          return (
            <>
              <div className="row" key={user.id}>
                <div className="col-12 user-card  mb-4">
                  <div className="row h-100 pt-3 pr-0 pb-3 pl-4 ">
                    <div className="col-12 col-md-10">
                      <div className="row">
                        <div className="col-12 d-flex">
                          <p className="mb-1 p-0 modificar-usuario-label">
                            {" "}
                            Nombre:{" "}
                          </p>
                          <p className="mb-1 p-0">{user.name}</p>
                        </div>

                        <div className="col-12 d-flex">
                          <p className="mb-1 p-0 modificar-usuario-label">
                            Email:
                          </p>
                          <p className="mb-1 p-0">{user.email}</p>
                        </div>
                        <div className="modificar-usuario-controles">
                          <div className="col-12 d-flex justify-content-end mt-2 mb-2">
                            {user.email != "admin@aajc.com" ? (
                              <button
                                className="btn btn-danger user-card-button d-flex align-items-center"
                                onClick={() => handleRemove(user.id)}
                              >
                                Eliminar
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default Usuario;
