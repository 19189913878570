import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import AddContact from "./components/AddContact";
import EditContact from "./components/EditContact";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Error404 from "./components/Error404";
import AdminPanel from "./components/AdminPanel";
import AddAdmin from "./components/AddAdmin";
import { useSelector } from "react-redux";


function App() {
  const isLogIn = useSelector((state) => state.isLogIn);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Layout>
        <Routes>
          <Route path="/edit/:id" element={<EditContact />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/add" element={<AddContact />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/admin"
            element={<AdminPanel />}
          />
          <Route exact path="/addAdmin" element={<AddAdmin />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
